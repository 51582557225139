const courses = [
    { id: 1, course_code: '201101', course_name: 'القانون في حياتنا', prerequisite: null, credit_hours: 3, type: 'اختياري جامعة (اختيارية) (15)' },
    { id: 2, course_code: '202132', course_name: 'حقوق الانسان', prerequisite: null, credit_hours: 3, type: 'اختياري جامعة (اختيارية) (15)' },
    { id: 3, course_code: '311100', course_name: 'مقدمة إلى برمجة الحاسوب', prerequisite: null, credit_hours: 3, type: 'متطلب كلية اجباري (اجبارية) (21)' },
    { id: 4, course_code: '311101', course_name: 'لغة برمجة (1)', prerequisite: '311100', credit_hours: 3, type: 'متطلب كلية اجباري (اجبارية) (21)' },
    { id: 5, course_code: '311160', course_name: 'اخلاقيات الحاسوب', prerequisite: null, credit_hours: 3, type: 'متطلب كلية اجباري (اجبارية) (21)' },
    { id: 6, course_code: '311202', course_name: 'البرمجة الكينونية', prerequisite: '311101', credit_hours: 3, type: 'متطلب كلية اجباري (اجبارية) (21)' },
    { id: 7, course_code: '311210', course_name: 'الرياضيات المتقطعة لطلبة تكنولوجيا المعلومات', prerequisite: '601101', credit_hours: 3, type: 'متطلب كلية اجباري (اجبارية) (21)' },
    { id: 8, course_code: '311213', course_name: 'تراكيب البيانات', prerequisite: '311202', credit_hours: 3, type: 'متطلب تخصص اجباري (اجبارية) (66)' },
    { id: 9, course_code: '311220', course_name: 'تصميم المنطق الرقمي', prerequisite: '311100', credit_hours: 3, type: 'متطلب تخصص اجباري (اجبارية) (66)' },
    { id: 10, course_code: '311314', course_name: 'الخوارزميات', prerequisite: '311213', credit_hours: 3, type: 'متطلب تخصص اجباري (اجبارية) (66)' },
    { id: 11, course_code: '311330', course_name: 'شبكات الحاسوب', prerequisite: '311220', credit_hours: 3, type: 'متطلب تخصص اجباري (اجبارية) (66)' },
    { id: 12, course_code: '311340', course_name: 'إدارة نظم قواعد البيانات', prerequisite: '311100', credit_hours: 3, type: 'متطلب تخصص اجباري (اجبارية) (66)' },
    { id: 13, course_code: '311422', course_name: 'نظم التشغيل', prerequisite: '311314', credit_hours: 3, type: 'متطلب تخصص اجباري (اجبارية) (66)' },
    { id: 14, course_code: '313160', course_name: 'مقدمة إلى هندسة البرمجيات', prerequisite: null, credit_hours: 3, type: 'متطلب كلية اجباري (اجبارية) (21)' },
    { id: 15, course_code: '313204', course_name: 'برمجة متقدمة', prerequisite: '311101', credit_hours: 3, type: 'متطلب تخصص اختياري (اختيارية) (9)' },
    { id: 16, course_code: '313240', course_name: 'اساسيات قواعد البيانات', prerequisite: '311100', credit_hours: 3, type: 'متطلب تخصص اجباري (اجبارية) (66)' },
    { id: 17, course_code: '313241', course_name: 'تحليل وتصميم البرمجيات', prerequisite: '313240', credit_hours: 3, type: 'متطلب تخصص اجباري (اجبارية) (66)' },
    { id: 18, course_code: '313261', course_name: 'هندسة متطلبات البرمجيات', prerequisite: '313160', credit_hours: 3, type: 'متطلب تخصص اجباري (اجبارية) (66)' },
    { id: 19, course_code: '313262', course_name: 'مكونات وبنية البرمجيات', prerequisite: '313160', credit_hours: 3, type: 'متطلب تخصص اجباري (اجبارية) (66)' },
    { id: 20, course_code: '313263', course_name: 'انظمة الوسائط المتعددة', prerequisite: '311101', credit_hours: 3, type: 'متطلب تخصص اختياري (اختيارية) (9)' },
    { id: 21, course_code: '313267', course_name: 'المناهج الاساسية في هندسة البرمجيات', prerequisite: '313160', credit_hours: 3, type: 'متطلب تخصص اختياري (اختيارية) (9)' },
    { id: 22, course_code: '313269', course_name: 'إدارة المشاريع البرمجية', prerequisite: '313160', credit_hours: 3, type: 'متطلب تخصص اختياري (اختيارية) (9)' },
    { id: 23, course_code: '313304', course_name: 'تطوير تطبيقات الإنترنت', prerequisite: '311202', credit_hours: 3, type: 'متطلب تخصص اجباري (اجبارية) (66)' },
    { id: 24, course_code: '313305', course_name: 'تطبيقات البرمجة المرئية', prerequisite: '311202', credit_hours: 3, type: 'متطلب تخصص اجباري (اجبارية) (66)' },
    { id: 25, course_code: '313321', course_name: 'تنظيم ومعمارية الحاسوب', prerequisite: '311220', credit_hours: 3, type: 'متطلب تخصص اجباري (اجبارية) (66)' },
    { id: 26, course_code: '313350', course_name: 'برمجيات النظم الذكيه', prerequisite: '311314', credit_hours: 3, type: 'متطلب تخصص اجباري (اجبارية) (66)' },
    { id: 27, course_code: '313360', course_name: 'تصميم مواقع انترنت', prerequisite: null, credit_hours: 3, type: 'متطلب تخصص اختياري (اختيارية) (9)' },
    { id: 28, course_code: '313363', course_name: 'تصميم البرمجيات', prerequisite: '313262', credit_hours: 3, type: 'متطلب تخصص اجباري (اجبارية) (66)' },
    { id: 29, course_code: '313364', course_name: 'هندسة البرمجيات الكينونية', prerequisite: '313261', credit_hours: 3, type: 'متطلب تخصص اجباري (اجبارية) (66)' },
    { id: 30, course_code: '313365', course_name: 'اختبار البرمجيات وضمان الجودة', prerequisite: '313363', credit_hours: 3, type: 'متطلب تخصص اجباري (اجبارية) (66)' },
    { id: 31, course_code: '313366', course_name: 'جودة البرمجيات', prerequisite: '313363', credit_hours: 3, type: 'متطلب تخصص اختياري (اختيارية) (9)' },
    { id: 32, course_code: '313367', course_name: 'مواصفات البرمجيات', prerequisite: '313261', credit_hours: 3, type: 'متطلب تخصص اختياري (اختيارية) (9)' },
    { id: 33, course_code: '313462', course_name: 'معمارية البرمجيات', prerequisite: '313364', credit_hours: 3, type: 'متطلب تخصص اختياري (اختيارية) (9)' },
    { id: 34, course_code: '313463', course_name: 'هندسة البرمجيات الامنة', prerequisite: null, credit_hours: 3, type: 'متطلب تخصص اختياري (اختيارية) (9)' },
    { id: 35, course_code: '313464', course_name: 'إعادة هندسة البرمجيات وهيكلتها', prerequisite: '313364', credit_hours: 3, type: 'متطلب تخصص اختياري (اختيارية) (9)' },
    { id: 36, course_code: '313465', course_name: 'أمن البرمجيات', prerequisite: '311330', credit_hours: 3, type: 'متطلب تخصص اجباري (اجبارية) (66)' },
    { id: 37, course_code: '313466', course_name: 'توثيق البرمجيات', prerequisite: '313261', credit_hours: 3, type: 'متطلب تخصص اجباري (اجبارية) (66)' },
    { id: 38, course_code: '313468', course_name: 'صيانة وتطوير البرمجيات', prerequisite: '313365', credit_hours: 3, type: 'متطلب تخصص اجباري (اجبارية) (66)' },
    { id: 39, course_code: '313469', course_name: 'موضوعات خاصة في هندسة البرمجيات', prerequisite: '313364', credit_hours: 3, type: 'متطلب تخصص اختياري (اختيارية) (9)' },
    { id: 40, course_code: '313470', course_name: 'مشروع التخرج', prerequisite: null, credit_hours: 3, type: 'متطلب تخصص اجباري (اجبارية) (66)' },
    { id: 41, course_code: '313480', course_name: 'التدريب الميداني', prerequisite: null, credit_hours: 3, type: 'متطلب تخصص اجباري (اجبارية) (66)' },
    { id: 42, course_code: '401100', course_name: 'مبادىء الإدارة', prerequisite: null, credit_hours: 3, type: 'اختياري جامعة (اختيارية) (15)' },
    { id: 43, course_code: '601101', course_name: 'التفاضل والتكامل (1)', prerequisite: null, credit_hours: 3, type: 'متطلب كلية اجباري (اجبارية) (21)' },
    { id: 44, course_code: '601131', course_name: 'مبادىء الإحصاء', prerequisite: null, credit_hours: 3, type: 'مواد مساندة اجباري (اجبارية) (6)' },
    { id: 45, course_code: '601372', course_name: 'التحليل العددي (1)', prerequisite: null, credit_hours: 3, type: 'مواد مساندة اجباري (اجبارية) (6)' },
    { id: 46, course_code: '701140', course_name: 'المكتبة العربية والمعاجم', prerequisite: null, credit_hours: 3, type: 'اختياري جامعة (اختيارية) (15)' },
    { id: 47, course_code: '704099', course_name: 'مهارات اللغة العربية ( استدراكي )', prerequisite: null, credit_hours: 3, type: 'مواد استدراكية اجباري (اجبارية) (9)' },
    { id: 48, course_code: '704103', course_name: 'قضايا دولية وعربية معاصرة', prerequisite: null, credit_hours: 3, type: 'اختياري جامعة (اختيارية) (15)' },
    { id: 49, course_code: '704104', course_name: 'الثقافة الإسلامية', prerequisite: null, credit_hours: 3, type: 'اختياري جامعة (اختيارية) (15)' },
    { id: 50, course_code: '704107', course_name: 'الريادة والإبتكار', prerequisite: null, credit_hours: 1, type: 'متطلب جامعة اجباري (اجبارية) (12)' },
    { id: 51, course_code: '704108', course_name: 'النزاهة والشفافية', prerequisite: null, credit_hours: 3, type: 'اختياري جامعة (اختيارية) (15)' },
    { id: 52, course_code: '704109', course_name: 'القيادة والمسؤولية الاجتماعية', prerequisite: null, credit_hours: 1, type: 'متطلب جامعة اجباري (اجبارية) (12)' },
    { id: 53, course_code: '704110', course_name: 'الثقافة الرقمية', prerequisite: null, credit_hours: 3, type: 'اختياري جامعة (اختيارية) (15)' },
    { id: 54, course_code: '704112', course_name: 'مهارات اللغة العربية (2)', prerequisite: '704118', credit_hours: 3, type: 'اختياري جامعة (اختيارية) (15)' },
    { id: 55, course_code: '704113', course_name: 'فن الخطابة والحوار', prerequisite: null, credit_hours: 3, type: 'اختياري جامعة (اختيارية) (15)' },
    { id: 56, course_code: '704115', course_name: 'التنمية وتنظيم الأسرة في الإسلام', prerequisite: null, credit_hours: 3, type: 'اختياري جامعة (اختيارية) (15)' },
    { id: 57, course_code: '704116', course_name: 'المهارات الحياتية', prerequisite: null, credit_hours: 1, type: 'متطلب جامعة اجباري (اجبارية) (12)' },
    { id: 58, course_code: '704117', course_name: 'مهارات اللغة الانجليزية (1)', prerequisite: '714099', credit_hours: 2, type: 'متطلب جامعة اجباري (اجبارية) (12)' },
    { id: 59, course_code: '704118', course_name: 'مهارات اللغة العربية (1)', prerequisite: '704099', credit_hours: 2, type: 'متطلب جامعة اجباري (اجبارية) (12)' },
    { id: 60, course_code: '704119', course_name: 'التربية الوطنية', prerequisite: null, credit_hours: 2, type: 'متطلب جامعة اجباري (اجبارية) (12)' },
    { id: 61, course_code: '704141', course_name: 'تاريخ القدس', prerequisite: null, credit_hours: 3, type: 'اختياري جامعة (اختيارية) (15)' },
    { id: 62, course_code: '704151', course_name: 'مهارات الحاسوب (1)', prerequisite: '724099', credit_hours: 3, type: 'اختياري جامعة (اختيارية) (15)' },
    { id: 63, course_code: '704152', course_name: 'مهارات الحاسوب (2)', prerequisite: '704151', credit_hours: 3, type: 'اختياري جامعة (اختيارية) (15)' },
    { id: 64, course_code: '704163', course_name: 'اللغة الأجنبية غير اللغة الإنجليزية', prerequisite: null, credit_hours: 3, type: 'اختياري جامعة (اختيارية) (15)' },
    { id: 65, course_code: '704172', course_name: 'الرياضة والصحة', prerequisite: null, credit_hours: 3, type: 'اختياري جامعة (اختيارية) (15)' },
    { id: 66, course_code: '704200', course_name: 'العلوم العسكرية', prerequisite: null, credit_hours: 3, type: 'متطلب جامعة اجباري (اجبارية) (12)' },
    { id: 67, course_code: '714099', course_name: 'مهارات اللغة الإنجليزية (استدراكي)', prerequisite: null, credit_hours: 3, type: 'مواد استدراكية اجباري (اجبارية) (9)' },
    { id: 68, course_code: '724099', course_name: 'مهارات الحاسوب (استدراكي)', prerequisite: null, credit_hours: 3, type: 'مواد استدراكية اجباري (اجبارية) (9)' }
  ];
  
  module.exports = courses;
  