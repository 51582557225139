import React, { useState, useEffect } from 'react';
import { Container, Form, Button, Alert, Table } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './styles.css';
import courses from './data';

function App() {
  const [courseCode, setCourseCode] = useState('');
  const [alert, setAlert] = useState('');
  const [courseName, setCourseName] = useState('');
  const [relatedCourses, setRelatedCourses] = useState([]);
  const [searchedCode, setSearchedCode] = useState('');

  useEffect(() => {
    const banner = document.getElementById('banner');
    if (!localStorage.getItem('firstVisit')) {
      window.alert('مرحباً! هذا التطبيق مخصص لطلاب جامعة عجلون الوطنية تخصص هندسة البرمجيات لتخطيط جدولهم الدراسي');
      localStorage.setItem('firstVisit', 'true');
      banner.style.display = 'block';
    } else {
      banner.style.display = 'block';
    }
  }, []);

  const handleSearch = (e) => {
    e.preventDefault();

    if (!courseCode.trim()) {
      setAlert('Please enter a course code');
      return;
    }

    setAlert('');
    setSearchedCode(courseCode);
    setCourseCode('');

    const course = courses.find(c => c.course_code === courseCode);
    const courseName = course ? course.course_name : null;
    const relatedCourses = courses.filter(c => c.prerequisite === courseCode);

    if (courseName) {
      setCourseName(courseName);
      setRelatedCourses(relatedCourses);
    } else {
      setCourseName('');
      setRelatedCourses([]);
      setAlert('Course not found');
    }
  };

  return (
    <Container>
      <div id="banner" className="banner" style={{ display: 'none' }}>
        مرحباً! هذا التطبيق مخصص لطلاب جامعة عجلون الوطنية تخصص <span className="highlighted-text">هندسة البرمجيات</span> لتخطيط جدولهم الدراسي
      </div>
      <h1 className="text-center mb-4">Course Search</h1>
      <Form id="searchForm" className="mb-4" onSubmit={handleSearch}>
        <Form.Group controlId="courseCode">
          <Form.Label>Course Code</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter course code"
            value={courseCode}
            onChange={(e) => setCourseCode(e.target.value)}
          />
        </Form.Group>
        <Button variant="custom" type="submit" block>
          Search
        </Button>
      </Form>
      {alert && <Alert variant="danger" className="mt-4">{alert}</Alert>}
      <div id="courseTitle" className="mt-4 text-center">
        {courseName && (
          <div className="text-center course-info">
            <div><span className="highlight">Course Code:</span> <span className="course-result">{searchedCode}</span></div>
            <div><span className="highlight">Course Name:</span> <span className="course-result">{courseName}</span></div>
          </div>
        )}
      </div>
      <div id="results" className="mt-4 table-responsive">
        {relatedCourses.length > 0 ? (
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Course Code</th>
                <th>Course Name</th>
                <th>Credit Hours</th>
                <th>Type</th>
              </tr>
            </thead>
            <tbody>
              {relatedCourses.map(course => (
                <tr key={course.course_code}>
                  <td>{course.course_code}</td>
                  <td>{course.course_name}</td>
                  <td>{course.credit_hours}</td>
                  <td>{course.type}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          <p className="bold-text text-center responsive-text">No courses found.</p>
        )}
      </div>
      <footer className="text-center mt-4">
        <p>© 2024 Copyright by <a href="https://www.instagram.com/mustafa.s.eisa" target="_blank" rel="noopener noreferrer">Mustafa Eisa</a></p>
      </footer>
    </Container>
  );
}

export default App;
